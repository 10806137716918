import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import {ContentPageNode} from '../../types/content-types'
import MediaItem from './MediaItem'
import GenericLink from './Link'
import type {Response} from '../../types/response'
import RichText from './RichText'

type Props = {
  page?: ContentPageNode | null
  hideImageOnMobile?: boolean
  response?: Response | null
}

const Hero = ({page, response, hideImageOnMobile = false}: Props) => {
  if (!page) {
    return null
  }
  const isHomePage = page.type === 'node--homepage'
  const isContentPage = page.type === 'node--content_page'
  const media = response?.included?.find(
    el => el.id === page.relationships.field_media?.data?.id,
  )
  return (
    <section className={`section-box c-hero ${media ? 'with-image' : ''}`}>
      <Container fluid>
        <Row>
          <Col lg={media ? '6' : '10'} className="c-hero__intro">
            {media && !hideImageOnMobile && (
              <MediaItem
                priority
                item={media}
                objectFit="contain"
                response={response}
                imageClass="max-w-100 h-auto"
                wrapperClass="c-hero__image--mobile"
              />
            )}
            <h1>{page.attributes.title}</h1>
            {!isHomePage && page.attributes.field_subtitle && (
              <p className="c-hero__intro-subtitle mt-4 mb-0">
                {page.attributes.field_subtitle}
              </p>
            )}
            {page.attributes.field_description && (
              <RichText
                extraClass="c-hero__intro-description"
                html={page.attributes.field_description.processed}
              />
            )}
            {page.attributes.field_button ? (
              <div className="w-100 mt-4 d-flex flex-column flex-sm-row flex-wrap c-hero__intro--buttons">
                {page.attributes.field_button ? (
                  <GenericLink
                    extraClass={`btn ${
                      isHomePage || isContentPage ? 'btn--red' : 'btn--orange'
                    } ${isContentPage || isHomePage ? 'btn--red-hero' : ''}`}
                    link={page.attributes.field_button}
                  >
                    {page.attributes.field_button.title}
                  </GenericLink>
                ) : null}
                {page.attributes.field_second_button ? (
                  <GenericLink
                    extraClass={`btn ${
                      isHomePage || isContentPage
                        ? 'btn--outline-red'
                        : 'btn--outline-orange'
                    }`}
                    link={page.attributes.field_second_button}
                  >
                    {page.attributes.field_second_button.title}
                  </GenericLink>
                ) : null}
              </div>
            ) : null}
          </Col>
          <Col
            className={`${
              media ? 'c-hero__image p-0 align-items-start' : 'd-none'
            }`}
          >
            {media && (
              <MediaItem
                response={response}
                priority
                item={media}
                objectFit="contain"
                imageClass="max-w-100 h-auto"
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Hero
