import React from 'react'
import {ContentPageNode} from '../../types/content-types'
import Hero from '../shared/Hero'
import type {Response} from '../../types/response'

type Props = {
  page?: ContentPageNode | null
  children?: React.ReactNode
  response?: Response | null
}

const ContentPage = ({page, children, response}: Props) => (
  <>
    <Hero response={response} page={page} />
    {children}
  </>
)

export default ContentPage
